a {
  color:inherit;
  text-decoration:none;
}

a:hover {
  cursor:pointer;
}

body {
  background-color: #EEEEEE;
  color:#636363;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size:14px;
  height: 100%;
  margin: 0;
  min-height: 100%;
}

li {
  list-style:none;
  margin:0;
  padding:4px 0 4px 0;
}

ul {
  list-style-type:none;
  margin:0;
  padding:0;
}

.body-content {
  display: flex;
  height: 100%;
  justify-content:center;
}

.copyrightContainer {
  align-self: center;
  background-color:#FFFFFF;
  font-weight: 400;
  padding: 10px 0px 20px 0px; /*20px 35px 20px 0;*/
  text-align: center;
  width:100%;
}

.footerLinkContainer {
  align-self: center;
  padding: 10px; /*20px 0 20px 35px;*/
  text-align: left;
}

.footerLinkList {
  text-align:center;
}

.footerLinkList > li {
  border-left: 1px solid #ADADAD;
  display: inline-block;
  margin: 0;
  padding: 0 10px 0 10px;
}

.footerLinkList > li:first-child {
  border-left: 0;
}

.heavy {
  font-weight:600;
}

.linksWindow {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
}

.linksWindow > div {
  flex: 1;
}

.linksWindowBanner {
  align-content:center;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.linksWindowBanner > button {
  max-width:49%;
}

.linksWindowBanner > a:first-child > img {
  margin-left:0;
  max-width:100%;
}

.linksWindowBanner > a:last-child > img {
  margin-right: 0;
  max-width: 100%;
}

.linksWindowContent {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px 0 10px 0;
}

.linksWindowContent > div {
  padding:10px 10px 10px 20px;
}

.loginButton {
  background-color:#F96300 !important;
  border-radius:0 !important;
  border-color:transparent !important;
  color:#FFFFFF !important;
  display: flex;
  font-size:16px !important;
  margin-top:20px !important;
  padding:10px 20px 10px 20px !important;
}

.loginWindow {
  background-repeat: no-repeat;
  background-color: #181617;
  background-size: cover;
  display:flex;
  flex: 1;
  flex-direction:column;
  justify-content:center;
}

.loginWindow > a {
  align-self: center;
}

.loginWindow > a {
  background-color:#F96300;
  border-radius:0;
  border-color:transparent;
  color:#FFFFFF;
  font-size:16px;
  margin-top:20px;
  padding:10px 20px 10px 20px;
}

.loginWindow div {
  align-self:center;
}

.loginWindow img {
  height: 60px;
}

.loginWindowTitle {
  color:#FFFFFF;
  display:flex;
  flex-direction:row;
  font-family:Arial, Helvetica, sans-serif;
  font-size:36px;
}

.loginWindowTitle > span {
  align-self:center;
  padding-left:5px;
}

.mainBox {
  align-items: center;
  align-self:center;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  padding-top: 50px;
}

.mainBoxContent {
  align-self: center !important;
  background-color: #FFFFFF;
  display: flex !important;
  flex: 1;
  flex-direction: row;
  max-height: 420px !important;
  max-width: 560px !important;
  min-height: 420px !important;
  min-width: 560px !important;
  width: 100% !important; /*75%;*/
}

.mainBoxFooter {
  background-color: #FFFFFF; /*#454545;*/
  color: #000000; /*#ADADAD;*/
  display: flex;
  flex-direction: column;
  max-width: 560px;
  min-width: 560px;
}

.mainBoxFooter > div {
  flex: 1;
}

.toolsLinksContainer {
  border-right: 1px solid #EEEEEE;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.quickLinksContainer {
  position: relative;
  width: 200px;
  height: fit-content;
  background-color: lightgrey;
  position: relative;
  bottom: 0px;
  right: 0px;
}

.quickLinksList {
  position:absolute;
  bottom: 0px;
  right: 0px;
  background-color: lightgrey;
  width: 200px;
  height: fit-content;
  bottom: -147px;
  right: -165px;
  line-height: 75%;
  color: #000000;
  padding:5px;
  font-family:Arial, Helvetica, sans-serif;
  font-size:14px;
}

.quickLinksContainer > div {
  display: flex;
  flex-direction: row;
}

.quickLinksContainer > div > ul {
  flex:1 1 auto;
} 